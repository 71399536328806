import { Injectable } from '@angular/core';

import { AuthorizedDataNode, PortalPassengerNodeInput } from '@app/services/api/api.types';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  protected state: State = { ...defaultState };

  protected authorizedData: Partial<AuthorizedDataNode> = {};

  getState() {
    return this.state;
  }

  setState(state: State) {
    this.state = state;
  }

  patchState(state: Partial<State>) {
    this.state = { ...this.state, ...state };
  }

  resetState() {
    this.state = { ...defaultState, portalLink: this.state.portalLink };
  }

  getAuthorizedData() {
    return this.authorizedData;
  }

  setAuthorizedData(authorizedData: AuthorizedDataNode) {
    this.authorizedData = authorizedData;
  }

  patchAuthorizedData(authorizedData: Partial<AuthorizedDataNode>) {
    this.authorizedData = { ...this.authorizedData, ...authorizedData };
  }

  resetAuthorizedData() {
    this.authorizedData = {};
  }
}

const defaultState: State = {
  portalLink: '',
  passengers: [],
};

export type State = {
  portalLink: string;
  passengers: PortalPassengerNodeInput[];
  disruptionProtection?: number;
  tipsAmount?: number;
  cancelForAnyReason?: number;
  priceDropProtection?: number;
  promocode?: string;
  promocodeDiscountAmount?: number;
};
