import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { take } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { ApiService } from '@app/services/api/api.service';
import { PortalOrderNode } from '@app/services/api/api.types';

@Component({
  selector: 'hidden-flights-modal',
  templateUrl: './hidden-flights-modal.component.html',
  styleUrls: ['./hidden-flights-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HiddenFlightsModalComponent implements OnInit {
  presentedOption: PortalOrderNode;

  @Input() option: PortalOrderNode;

  @Output() hiddenFlightsPresented = new EventEmitter();
  @Output() hideModal = new EventEmitter();
  @Output() requestToBook = new EventEmitter<PortalOrderNode>();

  constructor(
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    const hiddenFlightsPresented =
      this.activatedRoute.snapshot.queryParamMap.get('utm_source') !== 'agent_preview';

    this.apiService
      .portalSetHiddenFlightsPresented({
        portalLink: this.option.portalLink,
        hiddenFlightsPresented,
      })
      .pipe(take(1))
      .subscribe(({ result }) => {
        this.presentedOption = result;

        this.hiddenFlightsPresented.emit();
      });
  }
}
