import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PortalOrderNode } from '@app/services/api/api.types';
import { PortalTrackingEvents } from '@app/utils/tracking-events';

@Component({
  selector: 'portal-option-details',
  templateUrl: './option-details.component.html',
  styleUrls: ['./option-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionDetailsComponent {
  PortalTrackingEvents = PortalTrackingEvents;

  @Input() option: PortalOrderNode;
  @Input() portalLink: string;
  @Input() withHiddenFlightsV2: boolean;
}
