import { Pipe, PipeTransform } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ChatMessageAuthorType, ChatMessageNode } from '@app/services/chat/chat.types';

@Pipe({
  name: 'isShowTyping',
})
export class IsShowTypingPipe implements PipeTransform {
  sub = new BehaviorSubject(false);

  transform(chatMessage: ChatMessageNode | undefined = undefined) {
    this.sub.next(false);

    if (!chatMessage) {
      this.show();
    }

    if (chatMessage?.authorType === ChatMessageAuthorType.LEAD) {
      setTimeout(() => this.show(), timeoutMs);
    }

    return this.sub;
  }

  show() {
    this.sub.next(true);
    setTimeout(() => this.sub.next(false), timeoutMs);
  }
}

const timeoutMs = 5000;
