import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'chat-text',
  templateUrl: './chat-text.component.html',
  styleUrl: './chat-text.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatTextComponent extends FieldType<FieldTypeConfig> {}
