import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { PortalLeadNode } from '@app/services/api/api.types';
import { StateService } from '@app/services/state/state.service';
import { PortalTrackingEvents } from '@app/utils/tracking-events';

@Component({
  selector: 'bookings-and-trips-page',
  templateUrl: './bookings-and-trips-page.component.html',
  styleUrls: ['./bookings-and-trips-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingsAndTripsPageComponent implements OnInit {
  upcomingTrips: PortalLeadNode[];
  pastTrips: PortalLeadNode[];

  constructor(private stateService: StateService) {}

  ngOnInit() {
    this.upcomingTrips = this.stateService.getAuthorizedData().upcomingTrips || [];
    this.pastTrips = this.stateService.getAuthorizedData().pastTrips || [];

    const lead_portal_link = this.stateService.getState().portalLink;
    window.track({ event_name: PortalTrackingEvents.bookingsAndTripsPageOpened, lead_portal_link });
  }
}
