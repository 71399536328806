<ng-container *ngIf="portalLead$ | async as portalLead">
  <flight-info [portalOrder]="portalOrder" class="mx-4 mx-md-0"></flight-info>

  <stepper [step]="Steps.TravelerDetails" class="mt-3 mt-md-4 mx-4 mx-md-0"></stepper>

  <div class="my-4 my-md-8 d-flex flex-column flex-md-row">
    <div class="flex-1">
      <h2 class="semi-bold mb-4 mx-4 mx-md-0">Travelers</h2>

      <passengers-form
        (valueChange)="valueChange()"
        (formSubmit)="formSubmit($event)"
        (back)="tryBack()"
        [model]="model"
        [passengersLabels]="passengersLabels"
        [passengers]="passengers"
        [errors]="errors"
        [portalLink]="state.portalLink"
      >
        <div class="d-md-none mt-4">
          <h2 class="semi-bold mb-4 mx-4">Price summary</h2>

          <price-summary
            [option]="portalOrder"
            [disruptionProtection]="state.disruptionProtection"
            [tipsAmount]="state.tipsAmount"
            [cancelForAnyReason]="state.cancelForAnyReason"
            [priceDropProtection]="state.priceDropProtection"
            [promocodeDiscountAmount]="state.promocodeDiscountAmount"
          ></price-summary>
        </div>
      </passengers-form>
    </div>

    <div class="d-none d-md-block ml-md-4 summary">
      <h2 class="semi-bold mb-4">Price summary</h2>

      <price-summary
        [option]="portalOrder"
        [disruptionProtection]="state.disruptionProtection"
        [tipsAmount]="state.tipsAmount"
        [cancelForAnyReason]="state.cancelForAnyReason"
        [priceDropProtection]="state.priceDropProtection"
        [promocodeDiscountAmount]="state.promocodeDiscountAmount"
      ></price-summary>
    </div>
  </div>
</ng-container>

<ng-template #confirmTemplate>
  <go-back-confirm (back)="back()"></go-back-confirm>
</ng-template>
