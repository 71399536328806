const chatMessageNode = `
  id
  createdAt
  leadId
  authorType
  readAt
  htmlMessage
  showTextInput
  form
`;

export const chatMessages = () => {
  return `
    query ChatMessages($input: ChatMessagesInput!) {
      chatMessages(input: $input) {
        ${chatMessageNode}
      }
    }
  `;
};

export const unreadChatMessagesCount = () => {
  return `
    query UnreadChatMessagesCount($input: UnreadChatMessagesInput!) {
      unreadChatMessagesCount(input: $input)
    }
  `;
};

export const sendChatMessage = () => {
  return `
    mutation SendChatMessage($input: ChatMessageCreateInput!) {
      sendChatMessage(input: $input) {
        ok
        messageId
        lastMessages {
          ${chatMessageNode}
        }
      }
    }
  `;
};

export const markChatMessagesReadByLead = () => {
  return `
    mutation MarkChatMessagesReadByLead($input: MarkChatMessagesReadByLeadInput!) {
      markChatMessagesReadByLead(input: $input) {
        ok
      }
    }
  `;
};
