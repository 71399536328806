import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'chat-submit',
  templateUrl: './chat-submit.component.html',
  styleUrl: './chat-submit.component.scss',
})
export class ChatSubmitComponent extends FieldType<FieldTypeConfig> {
  submit() {
    if (typeof this.props.submit === 'function') {
      (this.props.submit as () => void)();
    }
  }
}
