import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ChatFormField } from '@app/forms/chat-message-form/chat-message-form.component';
import { PortalLeadNode } from '@app/services/api/api.types';
import { ChatMessageAuthorType, ChatMessageNode } from '@app/services/chat/chat.types';

@Component({
  selector: 'chat-message',
  templateUrl: './chat-message.component.html',
  styleUrl: './chat-message.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageComponent {
  ChatMessageAuthorType = ChatMessageAuthorType;

  @Input() chatMessage: ChatMessageNode;
  @Input() portalLead: PortalLeadNode;
  @Input() chatMessageFormFields: ChatFormField[] = [];

  @Output() send = new EventEmitter();
}
