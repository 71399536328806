import { Pipe, PipeTransform } from '@angular/core';

import { ChatFormField } from '@app/forms/chat-message-form/chat-message-form.component';
import { throwSentryError } from '@app/utils/sentry';

@Pipe({
  name: 'getChatMessageFormFields',
})
export class GetChatMessageFormFieldsPipe implements PipeTransform {
  transform(formJson: string, last: boolean) {
    if (!last) return [];

    try {
      return JSON.parse(formJson) as ChatFormField[];
    } catch (error) {
      console.log(error);
      throwSentryError('chat_message_form', error);
    }

    return [];
  }
}
