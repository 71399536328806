<ng-container *ngFor="let itinerary of option.flightItineraries; let first = first">
  <card
    *ngIf="itinerary.segments.length || (!itinerary.segments.length && withHiddenFlightsV2)"
    [ngClass]="{ 'mt-4': !first }"
    class="card"
  >
    <div *ngIf="itinerary.segments.length; else bonusFlight" data-id="flight" class="flex-1 flight">
      <div
        attr.track-scroll="{ 'event_name': {{ PortalTrackingEvents.quoteDetailsSeen }}, 'lead_portal_link': '{{ portalLink }}', 'option_portal_link': '{{ option.portalLink }}' }"
        class="d-flex flex-column flex-md-row align-items-md-center px-4 py-4 px-md-6 py-md-4 head"
      >
        <h3 class="semi-bold flex-wrap">
          <span data-id="from">{{ itinerary.departureAirport.cityName }}</span
          >&nbsp;&ndash;&nbsp;<span data-id="to"
            >{{ itinerary.finalDestinationAirport.cityName }}</span
          ><span class="mx-1 mx-md-2"><dot-separator></dot-separator></span
          ><span data-id="departure-at">{{ itinerary.departureAt | date }}</span>
        </h3>
        <div class="d-flex align-items-center ml-md-auto mt-2 mt-md-0">
          <img src="assets/icons/time.svg" alt="time" class="mr-1" />
          <p data-id="travel-time">{{ itinerary.travelTime | travelTime }}</p>
        </div>
      </div>
      <div class="d-flex px-4 d-md-none">
        <hr />
      </div>
      <ng-container
        *ngFor="let segment of itinerary.segments; let lastSegment = last; let index = index"
      >
        <div
          [ngClass]="{ 'pb-4': lastSegment }"
          class="d-flex flex-column px-4 px-md-6 pt-4"
          data-id="leg"
        >
          <div class="d-flex align-items-center">
            <img [src]="segment.flightSegment.airline.logoUrl" alt="airline" class="logo" />
            <div class="d-flex flex-column">
              <p class="caption d-flex flex-wrap ml-4">
                <span data-id="airline-short-name" class="nowrap"
                  >{{ segment.flightSegment.airline.shortName }}</span
                >&nbsp;<span data-id="flight-number">{{ segment.flightSegment.flightNumber }}</span
                ><ng-container *ngIf="segment.flightSegment.operatedBy"
                  ><span class="mx-2"><dot-separator></dot-separator></span
                  ><span data-id="operated-by" class="nowrap"
                    >Operated by {{ segment.flightSegment.operatedBy }}</span
                  ></ng-container
                ><ng-container *ngIf="segment.flightSegment.aircraftName"
                  ><span class="mx-2"><dot-separator></dot-separator></span
                  ><span data-id="aircraft-name" class="nowrap"
                    >{{ segment.flightSegment.aircraftName }}</span
                  ></ng-container
                >
              </p>
            </div>
          </div>
          <div class="d-flex mt-4">
            <div class="d-flex justify-content-center mr-4 wrapper">
              <div class="line"></div>
            </div>
            <div class="d-flex flex-column">
              <div class="d-flex flex-column flex-md-row">
                <div class="d-flex flex-column">
                  <div class="d-flex">
                    <p class="semi-bold mr-2" data-id="departure-at">
                      {{ segment.flightSegment.departureAtAirportTimeZone | withoutTimezone: 'h:mm
                      a' }}
                    </p>

                    <p data-id="departure-airport">
                      {{ segment.flightSegment.departureAirport.cityName }} ({{
                      segment.flightSegment.departureAirport.locationCode }})
                    </p>
                  </div>

                  <div
                    class="d-flex align-items-center"
                    [ngClass]="{ 'text-sun-crete-700': segment.nextDayDeparture }"
                  >
                    <p data-id="next-day-departure">
                      {{ segment.flightSegment.departureAtAirportTimeZone | withoutTimezone: 'EEE,
                      MMM d' }}
                    </p>

                    <ng-container *ngIf="segment.nextDayDeparture">
                      <dot-separator class="mx-2"></dot-separator>

                      <div class="d-flex align-items-center">
                        <img
                          src="assets/icons/flight-land.svg"
                          width="20"
                          height="20"
                          alt="flight-land"
                        />

                        <p class="caption text-sun-crete-700 ml-1">You will depart the next day</p>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>

              <div class="d-flex align-items-center">
                <div class="d-flex flex-column">
                  <div class="d-flex mt-4 mt-md-6">
                    <p data-id="travel-time">{{ segment.travelTime | travelTime }}</p>

                    <div
                      *ngIf="segment.flightSegment.isOvernight"
                      class="d-flex align-items-center"
                    >
                      <dot-separator class="mx-2"></dot-separator>

                      <overnight-flight-badge></overnight-flight-badge>
                    </div>
                  </div>

                  <p class="mb-4 mb-md-6" data-id="service-class">
                    {{ segment.flightSegment.serviceClass | label }}
                  </p>
                </div>
              </div>

              <div class="d-flex flex-column flex-md-row">
                <div class="d-flex flex-column">
                  <div class="d-flex">
                    <p class="semi-bold mr-2" data-id="arrival-at">
                      {{ segment.flightSegment.arrivalAtAirportTimeZone | withoutTimezone: 'h:mm a'
                      }}
                    </p>

                    <p data-id="arrival-airport">
                      {{ segment.flightSegment.arrivalAirport.cityName }} ({{
                      segment.flightSegment.arrivalAirport.locationCode }})
                    </p>
                  </div>

                  <div
                    class="d-flex align-items-center"
                    [ngClass]="{ 'text-sun-crete-700': segment.nextDayArrival }"
                  >
                    <p data-id="next-day-arrival">
                      {{ segment.flightSegment.arrivalAtAirportTimeZone | withoutTimezone: 'EEE, MMM
                      d' }}
                    </p>

                    <ng-container *ngIf="segment.nextDayArrival">
                      <dot-separator class="mx-2"></dot-separator>

                      <div class="d-flex align-items-center">
                        <img
                          src="assets/icons/flight-land.svg"
                          width="20"
                          height="20"
                          alt="flight-land"
                        />

                        <p class="caption text-sun-crete-700 ml-1">You will arrive the next day</p>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ng-container
            *ngIf="itinerary.finalDestinationAirport.locationCode === itinerary.arrivalAirport.locationCode"
          >
            <div *ngIf="segment.layover" class="d-flex py-4 mt-4 block">
              <img src="assets/icons/layover.svg" width="20" height="20" alt="layover" />

              <div class="d-flex flex-column ml-4">
                <p>
                  Layover
                  <span data-id="wait-time">{{ segment.layover.waitTime | travelTime }}</span> in

                  <span data-id="arrival-airport"
                    >{{ segment.flightSegment.arrivalAirport.cityName }} ({{
                    segment.flightSegment.arrivalAirport.locationCode }})</span
                  >
                </p>

                <p
                  *ngIf="segment.layover.selfTransfer"
                  class="caption text-sun-crete-700 mt-1"
                  data-id="self-transfer"
                >
                  Self-transfer: On this trip, you may need to check yourself and your bags in each
                  individual flight.
                </p>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="withHiddenFlightsV2">
        <div
          *ngIf="itinerary.finalDestinationAirport.locationCode !== itinerary.arrivalAirport.locationCode"
          class="d-flex px-4 px-md-6 mb-4"
        >
          <extra-leg [itinerary]="itinerary"></extra-leg>
        </div>
      </ng-container>
    </div>

    <ng-template #bonusFlight>
      <ng-container *ngIf="withHiddenFlightsV2">
        <div class="d-flex px-4 px-md-8 py-4">
          <bonus-flight [itinerary]="itinerary"></bonus-flight>
        </div>
      </ng-container>
    </ng-template>
  </card>
</ng-container>
