import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'old-lead-page',
  templateUrl: './old-lead-page.component.html',
  styleUrls: ['./old-lead-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OldLeadPageComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    const leadId = this.activatedRoute.snapshot.paramMap.get('lead-id');
    const queryParams = this.activatedRoute.snapshot.queryParams;

    if (leadId) {
      void this.router.navigate([`/${leadId}`], { queryParams });
    }
  }
}
