import { DateFieldComponent } from '@app/forms/formly/types/date-field/date-field.component';
import { InputFieldComponent } from '@app/forms/formly/types/input-field/input-field.component';
import { RadioFieldComponent } from '@app/forms/formly/types/radio-field/radio-field.component';
import { RepeatCardsComponent } from '@app/forms/formly/types/repeat-cards/repeat-cards.component';
import {
  BackendValidatorMessage,
  EmailValidator,
  RequiredValidatorMessage,
} from '@app/forms/formly/validators/validators';
import { FieldWrapperComponent } from '@app/forms/formly/wrappers/field-wrapper/field-wrapper.component';
import { DropdownFieldComponent } from '@app/forms/formly/types/dropdown-field/dropdown-field.component';
import { CcNumberFieldComponent } from '@app/forms/formly/types/cc-number-field/cc-number-field.component';
import { CcExpirationFieldComponent } from '@app/forms/formly/types/cc-expiration-field/cc-expiration-field.component';
import { CcCvvFieldComponent } from '@app/forms/formly/types/cc-cvv-field/cc-cvv-field.component';
import { CurrencyFieldComponent } from '@app/forms/formly/types/currency-field/currency-field.component';
import { InfoBlockFieldComponent } from '@app/forms/formly/types/info-block-field/info-block-field.component';
import { CardWrapperComponent } from '@app/forms/formly/wrappers/card-wrapper/card-wrapper.component';
import { CustomFieldComponent } from '@app/forms/formly/types/custom-field/custom-field.component';
import { CheckboxFieldComponent } from '@app/forms/formly/types/checkbox-field/checkbox-field.component';
import { ChatTextComponent } from '@app/forms/formly/types/chat-text/chat-text.component';
import { ChatSubmitComponent } from '@app/forms/formly/types/chat-submit/chat-submit.component';
import { ChatRadioButtonComponent } from '@app/forms/formly/types/chat-radio-button/chat-radio-button.component';
import { ChatEmailComponent } from '@app/forms/formly/types/chat-email/chat-email.component';
import { ChatFieldWrapperComponent } from '@app/forms/formly/wrappers/chat-field-wrapper/chat-field-wrapper.component';

export const formlyConfig = {
  types: [
    { name: 'input', component: InputFieldComponent, wrappers: ['field-wrapper'] },
    {
      name: 'cc-expiration',
      component: CcExpirationFieldComponent,
      wrappers: ['field-wrapper'],
    },
    {
      name: 'cc-cvv',
      component: CcCvvFieldComponent,
      wrappers: ['field-wrapper'],
    },
    {
      name: 'cc-number',
      component: CcNumberFieldComponent,
      wrappers: ['field-wrapper'],
    },
    { name: 'radio', component: RadioFieldComponent, wrappers: ['field-wrapper'] },
    { name: 'checkbox', component: CheckboxFieldComponent, wrappers: ['field-wrapper'] },
    { name: 'date', component: DateFieldComponent, wrappers: ['field-wrapper'] },
    { name: 'dropdown', component: DropdownFieldComponent, wrappers: ['field-wrapper'] },
    { name: 'repeat-cards', component: RepeatCardsComponent, wrappers: ['field-wrapper'] },
    { name: 'currency', component: CurrencyFieldComponent, wrappers: ['field-wrapper'] },
    { name: 'info-block', component: InfoBlockFieldComponent },
    { name: 'custom', component: CustomFieldComponent },
    { name: 'chat_text', component: ChatTextComponent, wrappers: ['chat-field-wrapper'] },
    { name: 'chat_email', component: ChatEmailComponent, wrappers: ['chat-field-wrapper'] },
    { name: 'chat_submit', component: ChatSubmitComponent },
    { name: 'chat_radio_button', component: ChatRadioButtonComponent },
  ],
  wrappers: [
    { name: 'field-wrapper', component: FieldWrapperComponent },
    { name: 'chat-field-wrapper', component: ChatFieldWrapperComponent },
    { name: 'card-wrapper', component: CardWrapperComponent },
  ],
  validators: [{ name: 'email', validation: EmailValidator }],
  validationMessages: [
    { name: 'required', message: RequiredValidatorMessage },
    { name: 'backend', message: BackendValidatorMessage },
    { name: 'max', message: 'Incorrect amount' },
    { name: 'invalid', message: 'Invalid value' },
    { name: 'cc-number-invalid', message: 'Please enter a valid card number' },
    { name: 'email', message: 'Invalid email address' },
  ],
};
