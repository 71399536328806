import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { PortalSubmitOptionErrors } from '@app/forms/card-form/card-form.component';

@Component({
  selector: 'promocode',
  templateUrl: './promocode.component.html',
  styleUrl: './promocode.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromocodeComponent implements OnInit, OnChanges {
  form: FormGroup;

  loading: boolean;

  @Input() promocode?: string;
  @Input() errors: PortalSubmitOptionErrors;

  @Output() applyPromocode = new EventEmitter();
  @Output() removePromocode = new EventEmitter();

  @HostListener('document:keydown.enter') onEnter() {
    this.submit();
  }

  ngOnInit() {
    this.form = new FormGroup({
      promocode: new FormControl('', { validators: [Validators.required] }),
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const errors = changes['errors']?.currentValue as PortalSubmitOptionErrors;

    if (errors && errors.promocode) {
      this.loading = false;

      this.form.enable();
      this.form.setErrors({ promocode: errors.promocode });
    }
  }

  submit() {
    this.loading = true;

    this.form.disable();

    const value = this.form.get('promocode')?.value as string;

    this.applyPromocode.emit(value);
  }
}
