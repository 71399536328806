const totalTravelTimeNode = `
  days
  hours
  minutes
`;

const portalAirportNode = `
  country {
    name
  }
  cityName
  locationCode
`;

const portalOrderNode = `
  portalLink
  cashback {
    eligible
    amount
  }
  acceptStripe
  alphaName
  portalDescription
  soldAmount
  onlinePrice
  savePrice
  bookingStatus
  hasThrowawayTicketing
  hasHiddenCityTicketing
  hiddenFlightsPresented
  flightItineraries {
    travelTime {
      ${totalTravelTimeNode}
    }
    departureAirport {
      locationCode
      cityName
    }
    arrivalAirport {
      locationCode
      cityName
    }
    finalDestinationAirport {
      locationCode
      cityName
    }
    departureAt
    daysShift
    segments {
      flightSegment {
        departureAtAirportTimeZone
        arrivalAtAirportTimeZone
        serviceClass
        airline {
          logoUrl
          shortName
        }
        flightNumber
        aircraftName
        isOvernight
        operatedBy
        departureAirport {
          ${portalAirportNode}
        }
        arrivalAirport {
          ${portalAirportNode}
        }
      }
      layover {
        waitTime {
          ${totalTravelTimeNode}
        }
        selfTransfer
      }
      travelTime {
        ${totalTravelTimeNode}
      }
      longest
      nextDayDeparture
      nextDayArrival
    }
  }
  hotelBookings {
    name
    guestCount
    city
    checkInDate
    checkoutDate
  }
  numberOfAdults
  numberOfChildren
  numberOfInfants
  soldAmountPerAdult
  soldAmountPerChild
  soldAmountPerInfant
  totalPassengers
  disruptionProtectionAmount
  disruptionProtectionAmountPerPaidPassenger
  cancelForAnyReasonAmount
  cancelForAnyReasonAmountPerPaidPassenger
  cancelForAnyReasonBalanceRefundPerTraveler
  cancelForAnyReasonRefundPerTraveler
  disclaimersHtml
  disclaimersCheckmarkHtml
  disclaimersWithAdditionalProductsHtml
  isDemo
  isChange
  isCancelForAnyReasonEnabled
  isDisruptionProtectionEnabled
  maxBalanceAmount
  priceDropProtectionEnabled
  priceDropProtectionAmount
  priceDropProtectionAmountPerTraveler
`;

const portalCreditCardNode = `
  id
  number
  holder
  expiration
  type
`;

const authorizedDataNode = `
  passengers {
    id
    countryId
    firstName
    middleName
    lastName
    fullName
    birthday
    gender
  }
  creditCards {
    ${portalCreditCardNode}
  }
  stripeCreditCards {
    ${portalCreditCardNode}
  }
  verification {
    required
    emailMasked
    emailSentAt
  }
  clientBalanceAmount
`;

const firstTrip = `
  departureAt
  serviceClass
  passengerCount
  departureAirport {
    ${portalAirportNode}
  }
  arrivalAirport {
    ${portalAirportNode}
  }
`;

export const portalLead = () => {
  return `
    query PortalLead($portalLink: ID!) {
      portalLead(portalLink: $portalLink) {
        portalLink
        firstName
        lastName
        salesAgent {
          fullName
          email
          phone
          picture
          deals
          avgSavings
          portalFeatures
          shift {
            startDateTimeAtUtc
            endDateTimeAtUtc
          }
        }
        firstTrip {
          ${firstTrip}
        }
        flightRequest {
          isLost
          createdAt
          portalLink
          publishedOptions {
            edges {
              node {
                ${portalOrderNode}
              }
            }
          }
        }
        roundTripDepartureAt
        tripType
      }
    }
  `;
};

export const portalUpdateOption = () => {
  return `
    mutation portalUpdateOption($inputUpdate: PortalUpdateOptionInput) {
      portalUpdateOption(inputUpdate: $inputUpdate) {
        ok
      }
    }
  `;
};

export const portalSubmitOption = () => {
  return `
    mutation PortalSubmitOption($input: PortalSubmitOptionInput) {
      portalSubmitOption(input: $input) {
        ok
        errors
        stripeClientSecret
      }
    }
  `;
};

export const portalSubmitPassengers = () => {
  return `
    mutation PortalSubmitPassengers($input: PortalSavePassengersInput!) {
      portalSubmitPassengers(input: $input) {
        ok
        errors
        passengers {
          id
          countryId
          firstName
          middleName
          lastName
          fullName
          birthday
          gender
        }
      }
    }
  `;
};

export const portalSetHiddenFlightsPresented = () => {
  return `
    mutation PortalSetHiddenFlightsPresented($portalLink: ID!, $hiddenFlightsPresented: Boolean) {
      portalSetHiddenFlightsPresented(portalLink: $portalLink, hiddenFlightsPresented: $hiddenFlightsPresented) {
        ok
        result {
          ${portalOrderNode}
        }
      }
    }
  `;
};

export const authorizedData = () => {
  return `
    query AuthorizedData($portalLink: ID!) {
      authorizedData(portalLink: $portalLink) {
        ${authorizedDataNode}
      }
    }
  `;
};

export const authorizedDataHistory = () => {
  return `
    query AuthorizedDataHistory($portalLink: ID!) {
      authorizedData(portalLink: $portalLink) {
        quotes {
          portalLink
          firstTrip {
            ${firstTrip}
          }
          roundTripDepartureAt
          tripType
        }
        pastTrips {
          portalLink
          firstTrip {
            ${firstTrip}
          }
          roundTripDepartureAt
          tripType
        }
        upcomingTrips {
          portalLink
          firstTrip {
            ${firstTrip}
          }
          roundTripDepartureAt
          tripType
        }
      }
    }
  `;
};

export const portalSendVerificationEmail = () => {
  return `
    mutation PortalSendVerificationEmail($portalLink: ID!) {
      portalSendVerificationEmail(portalLink: $portalLink) {
        ok
        result {
          ${authorizedDataNode}
        }
      }
    }
  `;
};

export const portalAcceptVerification = () => {
  return `
    mutation PortalAcceptVerification($portalLink: ID!, $verificationCode: String!) {
      portalAcceptVerification(portalLink: $portalLink, verificationCode: $verificationCode) {
        ok
        result {
          ${authorizedDataNode}
        }
      }
    }
  `;
};

export const sendPasswordToEmail = () => {
  return `
    mutation SendPasswordToEmail($portalLink: ID!, $email: Email!) {
      sendPasswordToEmail(portalLink: $portalLink, email: $email) {
        ok
      }
    }
  `;
};

export const portalOptionSelected = () => {
  return `
    mutation PortalOptionSelected($portalLink: ID!) {
      portalOptionSelected(portalLink: $portalLink) {
        ok
      }
    }
  `;
};

export const portalFinishPayment = () => {
  return `
    mutation PortalFinishPayment($portalLink: ID!) {
      portalFinishPayment(portalLink: $portalLink) {
        ok
      }
    }
  `;
};

export const validatePromocode = () => {
  return `
    mutation ValidatePromocode($input: ValidatePromocodeInput!) {
      validatePromocode(input: $input) {
        ok
        errors
        discountAmount
      }
    }
  `;
};
