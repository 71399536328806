import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { combineLatest, map, Observable } from 'rxjs';

import { PortalLeadService } from '@app/services/portal-lead/portal-lead.service';
import { PortalOrderService } from '@app/services/portal-order/portal-order.service';
import { State, StateService } from '@app/services/state/state.service';
import { PopupsService } from '@app/ui/services/popups.service';
import { PortalLeadNode, PortalOrderNode } from '@app/services/api/api.types';
import { Steps } from '@app/components/stepper/stepper.component';
import { beforeUnloadHandler } from '@app/utils/utils';
import { PortalTrackingEvents } from '@app/utils/tracking-events';

@Component({
  selector: 'premium-disruption-assistance-page',
  templateUrl: './premium-disruption-assistance-page.component.html',
  styleUrls: ['./premium-disruption-assistance-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PremiumDisruptionAssistancePageComponent implements OnInit, OnDestroy {
  Steps = Steps;

  portalLead$: Observable<PortalLeadNode | null>;

  portalOrder: PortalOrderNode;
  state: State;

  disruptionProtection: number;

  @ViewChild('confirmTemplate') confirmTemplate: TemplateRef<unknown>;

  constructor(
    private portalLeadService: PortalLeadService,
    private portalOrderService: PortalOrderService,
    private stateService: StateService,
    private popupsService: PopupsService,
    private location: Location,
    private router: Router,
  ) {}

  ngOnInit() {
    this.getPortalLead();

    window.track(PortalTrackingEvents.portalCheckoutDisruptionProtectionOpened);
  }

  getPortalLead() {
    this.portalLead$ = combineLatest([
      this.portalLeadService.portalLead$,
      this.portalOrderService.portalOrder$,
    ]).pipe(
      map(([portalLead, portalOrder]) => {
        if (portalOrder) {
          this.portalOrder = portalOrder;
        }

        this.state = this.stateService.getState();

        if (this.state.disruptionProtection) {
          this.disruptionProtection = this.state.disruptionProtection;
        }

        return portalLead;
      }),
    );
  }

  tryBack() {
    if (this.disruptionProtection) {
      this.popupsService.showModal(this.confirmTemplate);
      return;
    }

    this.back();
  }

  back() {
    this.location.back();

    window.track(PortalTrackingEvents.portalCheckoutDisruptionProtectionBackPressed);
  }

  selectProtection(disruptionProtection: number) {
    this.disruptionProtection = disruptionProtection;
    this.stateService.patchState({ disruptionProtection });

    window.addEventListener('beforeunload', beforeUnloadHandler); // todo how many times will the listener add?

    if (disruptionProtection) {
      window.track(PortalTrackingEvents.portalCheckoutDisruptionProtectionYesEntered);
    } else {
      window.track(PortalTrackingEvents.portalCheckoutDisruptionProtectionNoEntered);
    }
  }

  submit() {
    const path = [
      `${this.state.portalLink}`,
      `${this.portalOrder.portalLink}`,
      this.portalOrder.isCancelForAnyReasonEnabled
        ? `cancel-for-any-reason`
        : this.portalOrder.priceDropProtectionEnabled
          ? `price-drop-protection`
          : `gratitude`,
    ];

    void this.router.navigate(path);

    window.track(PortalTrackingEvents.portalCheckoutDisruptionProtectionContinuePressed);
  }

  ngOnDestroy() {
    window.removeEventListener('beforeunload', beforeUnloadHandler);
  }
}
