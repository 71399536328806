import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { Subject } from 'rxjs';

import { PortalSalesAgentNode } from '@app/services/api/api.types';
import { AuthService, User } from '@app/services/auth/auth.service';
import { PopupsService } from '@app/ui/services/popups.service';
import { bccPhone, containerWidth } from '@app/utils/constants';
import { StateService } from '@app/services/state/state.service';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  bccPhone = bccPhone;

  showedDetails: boolean;
  showedUser: boolean;

  user$: Subject<User | null>;

  @Input() salesAgent?: PortalSalesAgentNode;
  @Input() showLoginButton: boolean;
  @Input() withChat: boolean;

  @Output() signIn = new EventEmitter();
  @Output() signOut = new EventEmitter();

  constructor(
    private popupsService: PopupsService,
    private stateService: StateService,
    private authService: AuthService,
  ) {}

  get clientBalanceAmount() {
    return this.stateService.getAuthorizedData().clientBalanceAmount || 0;
  }

  ngOnInit() {
    this.user$ = this.authService.user$;
  }

  toggleDetails(detailsTemplate: TemplateRef<unknown>) {
    if (window.innerWidth < containerWidth) {
      this.popupsService.showModal(detailsTemplate);
    } else {
      this.showedDetails = !this.showedDetails;
    }
  }

  toggleUser(userTemplate: TemplateRef<unknown>) {
    if (window.innerWidth < containerWidth) {
      this.popupsService.showModal(userTemplate);
    } else {
      this.showedUser = !this.showedUser;
    }
  }
}
