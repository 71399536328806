import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';

import {
  authorizedData,
  authorizedDataHistory,
  portalAcceptVerification,
  portalSendVerificationEmail,
} from '@app/services/api/api.graphql';
import { AuthorizedDataNode } from '@app/services/api/api.types';
import { AuthService } from '@app/services/auth/auth.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiProtectedService {
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
  ) {}

  get headers() {
    const user = this.authService.user;

    if (user?.token) {
      return { Authorization: `Bearer ${user.token}` };
    }

    return {};
  }

  authorizedData(variables: { portalLink: string }) {
    const query = authorizedData();

    const headers = this.headers as HttpHeaders;

    return this.httpClient
      .post<{
        data: { authorizedData: AuthorizedDataNode };
      }>(protectedBaseUrl, { query, variables }, { headers })
      .pipe(map((response) => response.data.authorizedData));
  }

  authorizedDataHistory(variables: { portalLink: string }) {
    const query = authorizedDataHistory();

    const headers = this.headers as HttpHeaders;

    return this.httpClient
      .post<{
        data: { authorizedData: AuthorizedDataNode };
      }>(protectedBaseUrl, { query, variables }, { headers })
      .pipe(map((response) => response.data.authorizedData));
  }

  portalSendVerificationEmail(variables: { portalLink: string }) {
    const query = portalSendVerificationEmail();

    const headers = this.headers as HttpHeaders;

    return this.httpClient
      .post<{
        data: { portalSendVerificationEmail: { ok: boolean; result: AuthorizedDataNode } };
      }>(protectedBaseUrl, { query, variables }, { headers })
      .pipe(map((response) => response.data.portalSendVerificationEmail));
  }

  portalAcceptVerification(variables: { portalLink: string; verificationCode: string }) {
    const query = portalAcceptVerification();

    const headers = this.headers as HttpHeaders;

    return this.httpClient.post<{
      data: { portalAcceptVerification: { ok: boolean; result: AuthorizedDataNode } };
      errors: { message: string }[];
    }>(protectedBaseUrl, { query, variables }, { headers });
  }
}

const protectedBaseUrl = environment.GRAPHQL_PROTECTED_URL as string;
