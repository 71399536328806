import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { PortalLeadNode } from '@app/services/api/api.types';
import { Steps } from '@app/components/stepper/stepper.component';
import { PortalTrackingEvents } from '@app/utils/tracking-events';

@Component({
  selector: 'travel-expert-info',
  templateUrl: './travel-expert-info.component.html',
  styleUrls: ['./travel-expert-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TravelExpertInfoComponent implements OnInit {
  Steps = Steps;

  @Input() portalLead: PortalLeadNode;
  @Input() portalLink: string;

  ngOnInit() {
    const lead_portal_link = this.portalLink;
    window.track({ event_name: PortalTrackingEvents.leadWithAgentOpened, lead_portal_link });
  }
}
