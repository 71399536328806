<card class="d-flex p-4">
  <form [formGroup]="form" class="d-flex w-100">
    <div class="d-flex flex-column w-100">
      <input
        [ngClass]="{ 'error': form.errors }"
        formControlName="promocode"
        type="text"
        class="input"
        placeholder="Enter Code"
        data-id="promocode"
      />

      <p *ngFor="let error of form.errors | keyvalue" class="mt-1 text-red-600" data-id="error">
        {{ error.value }}
      </p>
    </div>

    <div class="ml-4">
      <button
        *ngIf="promocode; else applyPromocodeTemplate"
        (click)="removePromocode.emit()"
        class="button secondary"
        data-id="remove"
      >
        Remove
      </button>

      <ng-template #applyPromocodeTemplate>
        <button
          (click)="submit()"
          [disabled]="loading || form.invalid || form.pristine"
          class="button"
          data-id="apply"
        >
          Apply
        </button>
      </ng-template>
    </div>
  </form>
</card>
