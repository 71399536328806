<ng-container *ngIf="portalLead$ | async as portalLead">
  <flight-info [portalOrder]="portalOrder" class="mx-4 mx-md-0"></flight-info>

  <stepper [step]="Steps.OverviewAndPayment" class="mt-3 mt-md-4 mx-4 mx-md-0"></stepper>

  <div class="my-4 my-md-8 d-flex flex-column flex-md-row">
    <div class="flex-1">
      <h2 class="semi-bold mb-4 mx-4 mx-md-0">Trip summary</h2>

      <portal-option-details
        [option]="portalOrder"
        [portalLink]="state.portalLink"
        class="option-details"
      ></portal-option-details>

      <card *ngIf="portalOrder.hotelBookings?.length" class="mt-4 d-flex">
        <hotel-bookings
          [hotelBookings]="portalOrder.hotelBookings"
          class="w-100 hotel-bookings"
        ></hotel-bookings>
      </card>

      <h2 class="semi-bold mt-4 mt-md-8 mb-4 mx-4 mx-md-0">Travelers</h2>

      <passengers-preview [passengers]="state.passengers"></passengers-preview>

      <ng-container *ngIf="portalOrder.acceptStripe && withStripe; else cardForm">
        <stripe-form
          (valueChange)="valueChange()"
          (formSubmit)="stripeFormSubmit($event)"
          (stripeFormSubmitCallback)="stripeFormSubmitCallback()"
          (setReadAndAcceptError)="readAndAcceptError = $event"
          (back)="tryBack()"
          [creditCards]="stripeCreditCards"
          [readAndAccept]="readAndAccept"
          [portalLink]="state.portalLink"
          [clientBalanceAmount]="clientBalanceAmount"
          [maxBalanceAmount]="portalOrder.maxBalanceAmount | getMaxBalanceAmount: clientBalanceAmount: state"
          [totalAmount]="portalOrder.soldAmount | getTotalAmount: state"
          [clientSecret]="clientSecret"
          class="mt-4 mt-md-8"
        >
          <ng-container *ngTemplateOutlet="promocodeTemplate"></ng-container>
          <ng-container *ngTemplateOutlet="termsTemplate"></ng-container>
        </stripe-form>
      </ng-container>

      <ng-template #cardForm>
        <card-form
          (valueChange)="valueChange()"
          (formSubmit)="formSubmit($event)"
          (setReadAndAcceptError)="readAndAcceptError = $event"
          (back)="tryBack()"
          [model]="model"
          [errors]="errors"
          [creditCards]="creditCards"
          [readAndAccept]="readAndAccept"
          [portalLink]="state.portalLink"
          [clientBalanceAmount]="clientBalanceAmount"
          [maxBalanceAmount]="portalOrder.maxBalanceAmount | getMaxBalanceAmount: clientBalanceAmount: state"
          [totalAmount]="portalOrder.soldAmount | getTotalAmount: state"
          class="mt-4 mt-md-8"
        >
          <ng-container *ngTemplateOutlet="promocodeTemplate"></ng-container>
          <ng-container *ngTemplateOutlet="termsTemplate"></ng-container>
        </card-form>
      </ng-template>

      <ng-template #promocodeTemplate>
        <div promocode class="mt-4 mt-md-8">
          <h2 class="semi-bold mb-4 mx-4 mx-md-0">Promo code</h2>

          <promocode
            (applyPromocode)="applyPromocode($event)"
            (removePromocode)="removePromocode()"
            [promocode]="state.promocode"
            [errors]="errors"
          ></promocode>
        </div>
      </ng-template>

      <ng-template #termsTemplate>
        <div terms class="mt-4 mt-md-8">
          <h2 class="semi-bold mb-4 mx-4 mx-md-0">Terms & conditions</h2>

          <term-of-use
            (selectReadAndAccept)="selectReadAndAccept($event)"
            [disclaimersHtml]="(state.disruptionProtection || state.cancelForAnyReason || state.priceDropProtection) ? portalOrder.disclaimersWithAdditionalProductsHtml : portalOrder.disclaimersHtml"
            [disclaimersCheckmarkHtml]="portalOrder.disclaimersCheckmarkHtml"
            [readAndAccept]="readAndAccept"
            [readAndAcceptError]="readAndAcceptError"
          ></term-of-use>
        </div>

        <div class="d-md-none mt-4">
          <h2 class="semi-bold mb-4 mx-4">Price summary</h2>

          <price-summary
            [option]="portalOrder"
            [disruptionProtection]="state.disruptionProtection"
            [tipsAmount]="state.tipsAmount"
            [cancelForAnyReason]="state.cancelForAnyReason"
            [priceDropProtection]="state.priceDropProtection"
            [promocodeDiscountAmount]="state.promocodeDiscountAmount"
          ></price-summary>
        </div>
      </ng-template>
    </div>

    <div class="d-none d-md-block ml-md-4 summary">
      <h2 class="semi-bold mb-4">Price summary</h2>

      <price-summary
        [option]="portalOrder"
        [disruptionProtection]="state.disruptionProtection"
        [tipsAmount]="state.tipsAmount"
        [cancelForAnyReason]="state.cancelForAnyReason"
        [priceDropProtection]="state.priceDropProtection"
        [promocodeDiscountAmount]="state.promocodeDiscountAmount"
      ></price-summary>
    </div>
  </div>
</ng-container>

<ng-template #confirmTemplate>
  <go-back-confirm (back)="back()"></go-back-confirm>
</ng-template>
