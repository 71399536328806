export enum ChatMessageAuthorType {
  AGENT = 'AGENT',
  LEAD = 'LEAD',
}

export type ChatMessageNode = {
  id: string;
  createdAt: string;
  leadId: string;
  authorType: ChatMessageAuthorType;
  readAt: string;
  htmlMessage: string;
  showTextInput: boolean;
  form: string;
};
