import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';

import { BaseFormComponent } from '@app/forms/base-form/base-form';

@Component({
  selector: 'chat-message-form',
  templateUrl: './chat-message-form.component.html',
  styleUrl: './chat-message-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageFormComponent extends BaseFormComponent {
  @Input() chatMessageFormFields: ChatFormField[] = [];

  override getFields(): FormlyFieldConfig[] {
    return this.chatMessageFormFields.map((field, i) => {
      const last = i === this.chatMessageFormFields.length - 1;

      return {
        key: field.name || i.toString(),
        type: field.type,
        className: last ? '' : 'mb-2',
        props: {
          placeholder: field.placeholder,
          required: field.required,
          values: field.values,
          submit: this.submit.bind(this),
        },
        validators: { validation: getChatFormFieldValidator(field) },
      };
    });
  }

  override submit() {
    const value = Object.values(this.form.value).join(' ');
    this.formSubmit.emit(value);

    this.form.disable();
  }
}

export type ChatFormField = {
  type: 'chat_text' | 'chat_email' | 'chat_radio_button' | 'chat_submit';
  name: string;
  placeholder: string;
  values: string[];
  required: boolean;
};

const getChatFormFieldValidator = (field: ChatFormField) => {
  return field.type === 'chat_email' ? ['email'] : [];
};
