import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  AfterViewInit,
  SimpleChanges,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { map, of, switchMap } from 'rxjs';
import { Appearance, Stripe, StripeCardNumberElement, StripeElements } from '@stripe/stripe-js';

import { errorsFormTexts } from '@app/forms/formly/validators/validators';
import { scrollTo } from '@app/utils/utils';
import { clientBalanceOption, newCreditCardOption } from '@app/utils/constants';
import { CardFormComponent } from '@app/forms/card-form/card-form.component';
import { throwSentryError } from '@app/utils/sentry';
import { environment } from '../../../environments/environment';
import { PortalTrackingEvents } from '@app/utils/tracking-events';

@Component({
  selector: 'stripe-form',
  templateUrl: './stripe-form.component.html',
  styleUrl: './stripe-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StripeFormComponent
  extends CardFormComponent
  implements AfterViewInit, OnChanges, OnDestroy
{
  stripe: Stripe | null = null;
  elements: StripeElements | null = null;

  cardNumber: StripeCardNumberElement | null = null;
  cardNumberErrors: string | null = null;
  cardNumberComplete: boolean;

  cardExpiryErrors: string | null = null;
  cardExpiryComplete: boolean;

  cardCvcErrors: string | null = null;
  cardCvcComplete: boolean;

  isNewCreditCard = true;

  @Input() clientSecret: string | null = null;

  @Output() stripeFormSubmitCallback = new EventEmitter();

  @ViewChild('customTemplate') customTemplate: TemplateRef<unknown>;
  @ViewChild('errorTemplate') errorTemplate: TemplateRef<unknown>;

  override ngAfterViewInit() {
    const sub = this.form.valueChanges.subscribe(() => this.valueChange.emit());

    this.valueChanges$.add(sub);

    this.fields = this.getFields();

    this.initializeStripe();

    this.changeDetectorRef.detectChanges();
  }

  override ngOnChanges(changes: SimpleChanges) {
    if (changes['clientSecret']?.currentValue) {
      void this.handleStripePayment();
    }

    super.ngOnChanges(changes);
  }

  override getFields(): FormlyFieldConfig[] {
    const clientBalanceAmountOpt = this.getClientBalanceOption();

    return [
      {
        fieldGroup: [
          {
            type: 'dropdown',
            className: 'mb-4',
            props: {
              options: of([clientBalanceAmountOpt]),
              disabled: true,
            },
            defaultValue: clientBalanceAmountOpt.value,
          },
          {
            key: 'clientBalanceAmount',
            type: 'currency',
            className: 'mb-4',
            props: {
              label: 'Amount due',
              required: true,
            },
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                const sub = field.formControl?.valueChanges.subscribe((value) => {
                  const cardAmount = this.totalAmount - value;

                  const formValue = { cardAmount: cardAmount > 0 ? cardAmount : 0 };

                  this.form.patchValue(formValue, { emitEvent: false });
                });

                this.valueChanges$.add(sub);
              },
            },
            expressions: {
              'props.max': () => this.maxBalanceAmount,
              defaultValue: () =>
                this.maxBalanceAmount > this.totalAmount ? this.totalAmount : this.maxBalanceAmount,
            },
          },
          {
            type: 'info-block',
            props: {
              text: `The maximum you can pay at this time is ${this.transformPortalCurrencyPipe(
                this.maxBalanceAmount,
              )}.`,
            },
          },
        ],
        className: 'mb-3',
        wrappers: ['card-wrapper'],
        expressions: {
          hide: this.isSplittedPayment$.pipe(map((vl) => !vl)),
          'props.disabled': this.isSplittedPayment$.pipe(map((vl) => !vl)),
        },
      },
      {
        fieldGroup: [
          {
            key: 'id',
            type: 'dropdown',
            className: 'mb-4',
            props: {
              placeholder: 'Payment method',
              options: this.isSplittedPayment$.pipe(
                switchMap((isSplittedPayment) => {
                  const options = [...(this.creditCards || [])];

                  if (!isSplittedPayment && this.clientBalanceAmount) {
                    options.push(clientBalanceAmountOpt);
                  }

                  return of(options);
                }),
              ),
            },
            defaultValue: newCreditCardOption.value,
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                let prevValue: unknown = field.formControl?.value;

                const sub = field.formControl?.valueChanges.subscribe((value) => {
                  if (value === prevValue) return;

                  prevValue = value;

                  this.cardNumberErrors = null;
                  this.cardExpiryErrors = null;
                  this.cardCvcErrors = null;

                  if (value === newCreditCardOption.value) {
                    this.isNewCreditCard = true;

                    const clientBalanceAmount =
                      this.maxBalanceAmount > this.totalAmount
                        ? this.totalAmount
                        : this.maxBalanceAmount;

                    const formValue = {
                      id: newCreditCardOption.value,
                      clientBalanceAmount,
                      cardAmount: this.totalAmount - clientBalanceAmount,
                    };

                    this.form.reset(formValue);

                    setTimeout(() => this.initializeStripe());
                  } else if (value === clientBalanceOption.value) {
                    this.isNewCreditCard = false;

                    const needsSplit = this.maxBalanceAmount < this.totalAmount;

                    if (needsSplit) {
                      this.split();
                    }
                  } else {
                    this.isNewCreditCard = false;

                    const foundCreditCard = this.creditCards?.find((p) => p.value === value);

                    const formValue = { ...(foundCreditCard?.extra || {}) };

                    setTimeout(() => this.form.patchValue(formValue)); // todo timeout
                  }
                });

                this.valueChanges$.add(sub);
              },
            },
            expressions: {
              hide: () => !this.creditCards?.length && !this.clientBalanceAmount,
            },
          },
          {
            key: 'holder',
            type: 'input',
            className: 'mb-4',
            props: {
              label: 'Name on Card',
              required: true,
              attributes: {
                autocomplete: 'cc-name',
              },
            },
            expressions: {
              hide: () => !this.isNotStripeNewCreditCard() || this.isClientBalance(),
              disabled: () => !this.isNotStripeNewCreditCard(),
            },
            hooks: {
              onInit: this.fieldOnInit.bind(this),
            },
          },
          {
            fieldGroup: [
              {
                key: 'expiration',
                type: 'cc-expiration',
                className: 'mr-2 flex-1',
                props: {
                  label: 'Expiration date',
                  required: true,
                  placeholder: 'MM/YY',
                  disabled: true,
                },
                expressions: {
                  hide: () => !this.isNotStripeNewCreditCard() || this.isClientBalance(),
                  disabled: () => !this.isNotStripeNewCreditCard(),
                },
                hooks: {
                  onInit: this.fieldOnInit.bind(this),
                },
              },
            ],
            fieldGroupClassName: 'd-flex w-50',
          },
          {
            type: 'custom',
            props: {
              customTemplate: this.customTemplate,
            },
          },
          {
            type: 'info-block',
            props: {
              text: `An amount of ${this.transformPortalCurrencyPipe(
                this.clientBalanceAmount > this.totalAmount
                  ? this.totalAmount
                  : this.clientBalanceAmount,
              )} will be deducted from your balance.`,
            },
            expressions: {
              hide: this.isNotClientBalance.bind(this),
              'props.disabled': this.isNotClientBalance.bind(this),
            },
          },
          {
            key: 'cardAmount',
            type: 'currency',
            props: {
              label: 'Amount due',
              required: true,
            },
            className: 'mt-4',
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                const sub = field.formControl?.valueChanges.subscribe((value) => {
                  const clientBalanceAmount = this.totalAmount - value;

                  const formValue = {
                    clientBalanceAmount: clientBalanceAmount > 0 ? clientBalanceAmount : 0,
                  };

                  this.form.patchValue(formValue, { emitEvent: false });
                });

                this.valueChanges$.add(sub);
              },
            },
            expressions: {
              hide: this.isSplittedPayment$.pipe(map((vl) => !vl)),
              'props.disabled': this.isSplittedPayment$.pipe(map((vl) => !vl)),
              'props.max': () => {
                const clientBalanceAmount = this.form.get('clientBalanceAmount')?.value || 0;

                return this.totalAmount - clientBalanceAmount;
              },
            },
          },
          {
            type: 'checkbox',
            key: 'saveCardToStripe',
            props: {
              checkboxLabel: 'Remember this card for future use',
            },
            className: 'mt-4',
            expressions: {
              hide: () => this.isNotStripeNewCreditCard() || !this.user,
              disabled: () => this.isNotStripeNewCreditCard() || !this.user,
            },
          },
        ],
        wrappers: ['card-wrapper'],
      },
    ];
  }

  isNotStripeNewCreditCard() {
    const idControl = this.form.get('id');
    return Boolean(idControl?.value !== newCreditCardOption.value && this.creditCards?.length);
  }

  override submit() {
    this.form.markAllAsTouched();

    let isStripeFieldsValid =
      this.cardNumberComplete &&
      !this.cardNumberErrors &&
      this.cardExpiryComplete &&
      !this.cardExpiryErrors &&
      this.cardCvcComplete &&
      !this.cardCvcErrors;

    isStripeFieldsValid = this.isNewCreditCard ? isStripeFieldsValid : true;

    if (this.form.valid && isStripeFieldsValid && this.readAndAccept) {
      this.disabledFields = Object.keys(this.form.controls).filter(
        (key) => this.form.get(key)?.disabled,
      );
      this.form.disable();

      const params = {
        clientBalanceAmount: this.form.get('clientBalanceAmount')?.value,
        saveCardToStripe: this.form.get('saveCardToStripe')?.value,
        stripeCreditCardId: this.form.get('id')?.value,
      };

      this.formSubmit.emit(params);
    } else if (!isStripeFieldsValid) {
      this.form.enable();

      if (this.elements) {
        this.elements.getElement('cardNumber')?.focus();
        this.elements.getElement('cardExpiry')?.focus();
        this.elements.getElement('cardCvc')?.focus();
      }

      this.form.setErrors({ reqired: errorsFormTexts.card });
      this.scrollToFirstError();
    } else {
      this.setReadAndAcceptError.emit(true);
      scrollTo('#read-and-accept');
    }

    const lead_portal_link = this.portalLink;
    window.track({ event_name: PortalTrackingEvents.submitStripe, lead_portal_link });
  }

  initializeStripe() {
    void import('@stripe/stripe-js')
      .then((module) => module.loadStripe(environment.STRIPE_PUBLIC_KEY, { locale: 'en' }))
      .then((stripe) => {
        this.stripe = stripe;

        if (this.stripe) {
          this.elements = this.stripe.elements({ appearance });

          this.initCardNumberField();
          this.initCardExpiryField();
          this.initCardCvcField();
          this.initAddressField();
        }
      });
  }

  initCardNumberField() {
    if (!this.elements) return;

    const cardNumber = this.elements.create('cardNumber', { style });

    cardNumber.mount('#card-number');

    cardNumber.on('change', (event) => {
      this.form.setErrors({ stripe: undefined });
      this.form.updateValueAndValidity();

      if (event.error) {
        this.cardNumberErrors = event.error.message;
      } else {
        this.cardNumberErrors = null;
      }

      this.valueChange.emit();

      this.cardNumberComplete = event.complete;
      this.changeDetectorRef.detectChanges();

      window.track(`${PortalTrackingEvents.number}_entered`);

      if (event.error) {
        window.track(`${PortalTrackingEvents.number}_validation_error`);
      }
    });

    cardNumber.on('blur', () => {
      if (!this.cardNumberErrors && !this.cardNumberComplete) {
        this.cardNumberErrors = errorsFormTexts.cardNumberErrors;
        this.changeDetectorRef.detectChanges();
      }
    });

    this.cardNumber = cardNumber;
  }

  initCardExpiryField() {
    if (!this.elements) return;

    const cardExpiry = this.elements.create('cardExpiry', { style });

    cardExpiry.mount('#card-expiry');

    cardExpiry.on('change', (event) => {
      this.form.setErrors({ stripe: undefined });
      this.form.updateValueAndValidity();

      if (event.error) {
        this.cardExpiryErrors = event.error.message;
      } else {
        this.cardExpiryErrors = null;
      }

      this.valueChange.emit();

      this.cardExpiryComplete = event.complete;
      this.changeDetectorRef.detectChanges();

      window.track(`${PortalTrackingEvents.expiration}_entered`);

      if (event.error) {
        window.track(`${PortalTrackingEvents.expiration}_validation_error`);
      }
    });

    cardExpiry.on('blur', () => {
      if (!this.cardExpiryErrors && !this.cardExpiryComplete) {
        this.cardExpiryErrors = errorsFormTexts.cardExpiryErrors;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  initCardCvcField() {
    if (!this.elements) return;

    const cardCvc = this.elements.create('cardCvc', { style });

    cardCvc.mount('#card-cvc');

    cardCvc.on('change', (event) => {
      this.form.setErrors({ stripe: undefined });
      this.form.updateValueAndValidity();

      if (event.error) {
        this.cardCvcErrors = event.error.message;
      } else {
        this.cardCvcErrors = null;
      }

      this.valueChange.emit();

      this.cardCvcComplete = event.complete;
      this.changeDetectorRef.detectChanges();

      window.track(`${PortalTrackingEvents.cvv2}_entered`);

      if (event.error) {
        window.track(`${PortalTrackingEvents.cvv2}_validation_error`);
      }
    });

    cardCvc.on('blur', () => {
      if (!this.cardCvcErrors && !this.cardCvcComplete) {
        this.cardCvcErrors = errorsFormTexts.cardCvcErrors;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  initAddressField() {
    if (!this.elements) return;

    const address = this.elements.create('address', { mode: 'billing' });

    address.on('change', () => {
      this.form.setErrors({ stripe: undefined });
      this.form.updateValueAndValidity();

      this.valueChange.emit();

      window.track(`${PortalTrackingEvents.portalCheckoutStripePaymentAddress}_entered`);
    });

    address.mount('#address');
  }

  async handleStripePayment() {
    if (!this.stripe || !this.clientSecret || !this.cardNumber) return;

    const addressElementValue = await this.elements?.getElement('address')?.getValue();
    const address = addressElementValue?.value;

    const options = this.isNewCreditCard
      ? {
          payment_method: {
            card: this.cardNumber,
            billing_details: { ...address },
          },
        }
      : {};

    const { error, paymentIntent } = await this.stripe.confirmCardPayment(
      this.clientSecret,
      options,
    );

    if (error) {
      console.log(error.message || error);

      if (error.type === 'card_error') {
        this.form.enable();

        if (stripeNumberErrors.includes(error.code as string)) {
          this.cardNumberErrors = error.message as string;
        } else if (stripeCvcErrors.includes(error.code as string)) {
          this.cardCvcErrors = error.message as string;
        } else if (stripeExpErrors.includes(error.code as string)) {
          this.cardExpiryErrors = error.message as string;
        } else {
          this.form.setErrors({ stripe: error.message as string });
        }

        this.scrollToFirstError();
      } else {
        this.showErrorModal();
      }

      throwSentryError('stripe_payment', error);
    } else if (paymentIntent && paymentIntent.status === 'succeeded') {
      this.stripeFormSubmitCallback.emit();
    }
  }

  showErrorModal() {
    this.popupsService.showModal(this.errorTemplate);
  }

  refreshPage() {
    window.location.reload();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();

    if (this.elements) {
      this.elements.getElement('cardNumber')?.destroy();
      this.elements.getElement('cardExpiry')?.destroy();
      this.elements.getElement('cardCvc')?.destroy();
      this.elements.getElement('address')?.destroy();
    }
  }
}

const style = {
  base: {
    color: '#0A1D3D',
    fontSize: '14px',
    lineHeight: '20px',
    '::placeholder': {
      color: '#898390',
    },
  },
  invalid: {
    color: '#0A1D3D',
  },
  complete: {
    color: '#0A1D3D',
  },
};

const appearance: Appearance = {
  disableAnimations: true,
  variables: {
    spacingUnit: '4px',
    fontFamily: '"Inter", "Roboto", arial, sans-serif',
  },
  rules: {
    '.Label': {
      color: '#898390',
      fontSize: '14px',
      lineHeight: '20px',
    },
    '.Input': {
      color: '#0A1D3D',
      fontSize: '14px',
      lineHeight: '20px',
      border: '1px solid #E2E0E3',
      borderRadius: '8px',
      backgroundColor: '#FFFFFF',
      boxShadow: '0 0 0 0 #000000',
      transition: 'border-color 200ms ease-out, background-color 200ms ease-out',
    },
    '.Input:hover': {
      backgroundColor: '#F1F1F1',
    },
    '.Input:focus': {
      border: '1px solid #E2E0E3',
      boxShadow: '0 0 0 0 #000000',
    },
    '.Input--invalid': {
      color: '#0A1D3D',
      border: '1px solid #E52E2E',
      boxShadow: '0 0 0 0 #000000',
    },
    '.Input--invalid:hover': {
      backgroundColor: '#FDEEEE',
    },
  },
};

const stripeNumberErrors = ['incorrect_number', 'invalid_number'];

const stripeCvcErrors = ['invalid_cvc', 'incorrect_cvc'];

const stripeExpErrors = ['expired_card', 'invalid_expiry_year', 'invalid_expiry_month'];
