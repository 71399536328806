import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiService, baseUrl } from '@app/services/api/api.service';
import {
  chatMessages,
  markChatMessagesReadByLead,
  sendChatMessage,
  unreadChatMessagesCount,
} from '@app/services/chat/chat.graphql';
import { ChatMessageNode } from '@app/services/chat/chat.types';

@Injectable({
  providedIn: 'root',
})
export class ChatService extends ApiService {
  constructor(override httpClient: HttpClient) {
    super(httpClient);
  }

  chatMessages(variables: {
    input: { portalLink: string; maxMessages?: number; messageId?: string };
  }) {
    const query = chatMessages();

    return this.httpClient.post<{ data: { chatMessages: ChatMessageNode[] } }>(baseUrl, {
      query,
      variables,
    });
  }

  unreadChatMessagesCount(variables: { input: { portalLink: string } }) {
    const query = unreadChatMessagesCount();

    return this.httpClient.post<{ data: { unreadChatMessagesCount: number } }>(baseUrl, {
      query,
      variables,
    });
  }

  sendChatMessage(variables: {
    input: { portalLink: string; message: string; maxMessages?: number };
  }) {
    const query = sendChatMessage();

    return this.httpClient.post<{
      data: {
        sendChatMessage: { ok: boolean; messageId: string; lastMessages: ChatMessageNode[] };
      };
    }>(baseUrl, { query, variables });
  }

  markChatMessagesReadByLead(variables: { input: { portalLink: string; messages?: string[] } }) {
    const query = markChatMessagesReadByLead();

    return this.httpClient.post<{ data: { markChatMessagesReadByLead: { ok: boolean } } }>(
      baseUrl,
      { query, variables },
    );
  }
}
