import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'chat-radio-button',
  templateUrl: './chat-radio-button.component.html',
  styleUrl: './chat-radio-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatRadioButtonComponent extends FieldType<FieldTypeConfig> {
  setValue(value: string) {
    this.formControl.setValue(value);

    if (typeof this.props.submit === 'function') {
      (this.props.submit as () => void)();
    }
  }
}
