export enum PortalTrackingEvents {
  /* quote */
  quoteOpened = 'quote_opened',
  quoteClosed = 'quote_closed',
  quoteDetailsOpened = 'quote_details_opened',
  quoteDetailsSeen = 'quote_details_seen',
  quoteOverviewSeen = 'quote_overview_seen',

  /* chat */
  chatScrollingBack = 'chat_scrolling_back',
  chatMessagesRead = 'chat_messages_read',
  portalClientMessageSent = 'portal_client_message_sent',
  chatAnswerSelected = 'chat_answer_selected',

  /* passenger form */
  firstName = 'portal_checkout_pax_{index}_first_name',
  middleName = 'portal_checkout_pax_{index}_middle_name',
  lastName = 'portal_checkout_pax_{index}_last_name',
  gender = 'portal_checkout_pax_{index}_gender_{value}',
  birthday = 'portal_checkout_pax_{index}_birthday',
  passportCountryId = 'portal_checkout_pax_{index}_country',

  /* passenger page */
  portalCheckoutPassengersOpened = 'portal_checkout_passengers_opened',
  portalCheckoutPassengersBackPressed = 'portal_checkout_passengers_back_pressed',
  submitPassengers = 'submit_passengers',

  /* premium disruption assistance page */
  portalCheckoutDisruptionProtectionOpened = 'portal_checkout_disruption_protection_opened',
  portalCheckoutDisruptionProtectionYesEntered = 'portal_checkout_disruption_protection_yes_entered',
  portalCheckoutDisruptionProtectionNoEntered = 'portal_checkout_disruption_protection_no_entered',
  portalCheckoutDisruptionProtectionBackPressed = 'portal_checkout_disruption_protection_back_pressed',
  portalCheckoutDisruptionProtectionContinuePressed = 'portal_checkout_disruption_protection_continue_pressed',

  /* cancel for any reason page */
  portalCheckoutCfarOpened = 'portal_checkout_cfar_opened',
  portalCheckoutCfarYesEntered = 'portal_checkout_cfar_yes_entered',
  portalCheckoutCfarNoEntered = 'portal_checkout_cfar_no_entered',
  portalCheckoutCfarBackPressed = 'portal_checkout_cfar_back_pressed',
  portalCheckoutCfarContinuePressed = 'portal_checkout_cfar_continue_pressed',

  /* gratitude page */
  portalCheckoutGratitudeOpened = 'portal_checkout_gratitude_opened',
  portalCheckoutGratitudeEntered = 'portal_checkout_gratitude_entered ',
  portalCheckoutGratitudeBackPressed = 'portal_checkout_gratitude_back_pressed',
  portalCheckoutGratitudeContinuePressed = 'portal_checkout_gratitude_continue_pressed',

  /* card form */
  number = 'portal_checkout_payment_card_number',
  holder = 'portal_checkout_payment_card_holder',
  expiration = 'portal_checkout_payment_expiration',
  cvv2 = 'portal_checkout_payment_cvv',
  countryId = 'portal_checkout_payment_country',
  billingAddress1 = 'portal_checkout_payment_address_line1',
  billingAddress2 = 'portal_checkout_payment_address_line2',
  state = 'portal_checkout_payment_state',
  city = 'portal_checkout_payment_city',
  zipCode = 'portal_checkout_payment_zip',

  /* card page */
  switchOnSplit = 'switch_on_split',
  switchOffSplit = 'switch_off_split',
  submitCard = 'submit_card',
  portalCheckoutPaymentBackPressed = 'portal_checkout_payment_back_pressed',
  portalCheckoutPaymentTermsChecked = 'portal_checkout_payment_terms_checked',

  /* stripe */
  submitStripe = 'submit_stripe',
  portalCheckoutStripePaymentAddress = 'portal_checkout_stripe_payment_address',

  /* thank you page */
  portalCheckoutSuccess = 'portal_checkout_success',

  /* additional old events */
  leadWithAgentOpened = 'lead_with_agent_opened',
  bookingsAndTripsPageOpened = 'bookings_and_trips_page_opened',
  portalOptionSelected = 'portal_option_selected',
  chatTabOpened = 'chat_tab_opened',
  flightOptionsTabOpened = 'flight_options_tab_opened',
  portalCheckoutPaymentOpened = 'portal_checkout_payment_opened',
  quotesPageOpened = 'quotes_page_opened',
}

export const getTrackingEvent = (key: string): string | undefined => {
  return PortalTrackingEvents[key as keyof typeof PortalTrackingEvents];
};
