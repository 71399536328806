<div class="d-flex align-items-center justify-content-between header">
  <a *ngIf="user$ | async as user; else logo" [routerLink]="['']">
    <ng-container *ngTemplateOutlet="logo"></ng-container>
  </a>

  <ng-template #logo>
    <img src="assets/images/bcc_logo.svg" alt="BCC" width="124" height="32" />
  </ng-template>

  <div class="d-flex align-items-center">
    <a href="tel:{{ salesAgent?.phone || bccPhone }}" class="link">
      <button class="button secondary d-none d-md-flex align-items-center">
        <img src="assets/icons/phone.svg" width="20" height="20" alt="phone" class="mr-2" />
        {{ (salesAgent?.phone || bccPhone) | phone }}
      </button>
    </a>

    <div
      *ngIf="salesAgent && !withChat"
      (click)="toggleDetails(detailsTemplate)"
      class="ml-5 d-flex align-items-center cursor-pointer agent"
      data-id="agent"
    >
      <div class="d-flex flex-column align-items-end">
        <p data-id="sales-agent-fullname">{{ salesAgent.fullName }}</p>

        <p class="caption text-jazlyn-700">Your travel expert</p>
      </div>

      <img [src]="salesAgent.picture" alt="agent" class="ml-2 picture" />

      <card
        *ngIf="showedDetails"
        (clickOutside)="showedDetails = false"
        class="d-flex flex-column align-items-center p-6 details cursor-default"
      >
        <img [src]="salesAgent.picture" alt="agent" class="details-picture" />

        <h3 class="semi-bold mt-4" data-id="sales-agent-fullname">{{ salesAgent.fullName }}</h3>

        <p *ngIf="salesAgent.shift" class="mt-1">
          {{ salesAgent.shift.startDateTimeAtUtc | date: 'shortTime' }} – {{
          salesAgent.shift.endDateTimeAtUtc | date: 'shortTime' }}
        </p>

        <div class="d-flex mt-2">
          <img src="assets/icons/phone.svg" width="20" height="20" alt="phone" class="mr-1" />
          <a href="tel:{{ salesAgent.phone }}" class="link">
            <p class="text-bleu-de-france-600" data-id="sales-agent-phone">
              {{ salesAgent.phone | phone }}
            </p>
          </a>
        </div>

        <div class="d-flex mt-2">
          <img src="assets/icons/mail.svg" width="20" height="20" alt="mail" class="mr-1" />
          <a href="mailto:{{ salesAgent.email }}" class="link">
            <p class="text-bleu-de-france-600 nowrap" data-id="sales-agent-email">
              {{ salesAgent.email }}
            </p>
          </a>
        </div>
      </card>
    </div>

    <ng-template #clientBalanceTemplate>
      <img
        src="assets/icons/account_balance_wallet_line.svg"
        width="20"
        height="20"
        alt="account_balance_wallet_line"
      />

      <p class="ml-2">{{ clientBalanceAmount | portalCurrency }}</p>
    </ng-template>

    <div
      *ngIf="user$ | async as user"
      class="d-none d-md-flex align-items-center ml-5 px-5 py-1 currency"
    >
      <ng-container *ngTemplateOutlet="clientBalanceTemplate"></ng-container>
    </div>

    <div
      *ngIf="user$ | async as user; else loginTemplate"
      (click)="toggleUser(userTemplate)"
      class="ml-5 cursor-pointer d-flex align-items-center user"
    >
      <img src="assets/icons/menu.svg" width="20" height="20" alt="menu" />

      <img
        [src]="user.picture || 'assets/icons/avatar.svg'"
        alt="user"
        class="d-none d-md-flex picture ml-2"
      />

      <ng-template #userTemplate>
        <div class="d-flex flex-column cursor-default">
          <div class="p-4 d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <img
                [src]="user.picture || 'assets/icons/avatar.svg'"
                alt="user"
                class="popup-picture"
              />

              <div class="d-flex flex-column ml-3">
                <p>{{ user.fullname }}</p>
                <p class="mt-1 text-jazlyn-700">{{ user.email }}</p>
              </div>
            </div>

            <div class="d-flex d-md-none">
              <ng-container *ngTemplateOutlet="clientBalanceTemplate"></ng-container>
            </div>
          </div>

          <hr />

          <div
            (click)="showedUser = false; signOut.emit()"
            class="p-4 d-flex align-items-center cursor-pointer"
          >
            <img src="assets/icons/logout.svg" alt="logout" />
            <p class="ml-2">Logout</p>
          </div>
        </div>
      </ng-template>

      <card *ngIf="showedUser" (clickOutside)="showedUser = false" class="user-details">
        <ng-container *ngTemplateOutlet="userTemplate"></ng-container>
      </card>
    </div>

    <ng-template #loginTemplate>
      <ng-container *ngIf="showLoginButton">
        <button (click)="signIn.emit()" class="d-none d-md-flex button ml-5">Sign in</button>

        <img
          (click)="signIn.emit()"
          src="assets/icons/menu.svg"
          width="20"
          height="20"
          alt="menu"
          class="ml-5 d-md-none"
        />
      </ng-container>
    </ng-template>
  </div>
</div>

<ng-template #detailsTemplate>
  <div
    *ngIf="salesAgent"
    (touchstart)="$event.stopPropagation()"
    class="d-flex justify-content-between p-4"
  >
    <div class="d-flex align-items-center">
      <img [src]="salesAgent.picture" alt="agent" class="picture" />

      <div class="d-flex flex-column ml-3">
        <h3 class="semi-bold" data-id="sales-agent-fullname">{{ salesAgent.fullName }}</h3>

        <p *ngIf="salesAgent.shift">
          {{ salesAgent.shift.startDateTimeAtUtc | date: 'shortTime' }} – {{
          salesAgent.shift.endDateTimeAtUtc | date: 'shortTime' }}
        </p>
      </div>
    </div>

    <div class="d-flex">
      <a href="tel:{{ salesAgent.phone }}">
        <button class="button secondary icon-button">
          <img src="assets/icons/phone.svg" width="20" height="20" alt="phone" />
        </button>
      </a>

      <a href="mailto:{{ salesAgent.email }}" class="ml-2">
        <button class="button secondary icon-button">
          <img src="assets/icons/mail.svg" width="20" height="20" alt="mail" />
        </button>
      </a>
    </div>
  </div>
</ng-template>
