import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { map } from 'rxjs';

import { PortalOrderService } from '@app/services/portal-order/portal-order.service';

@Injectable({
  providedIn: 'root',
})
export class PortalOrderPageGuard {
  constructor(
    private portalOrderService: PortalOrderService,
    private router: Router,
  ) {}

  canActivateChild(route: ActivatedRouteSnapshot) {
    return this.portalOrderService.portalOrder$.pipe(
      map((portalOrder) => {
        if (portalOrder) {
          return true;
        }

        const portalLink = route.parent?.parent?.paramMap.get('lead-id') || '';

        void this.router.navigate([portalLink ? `${portalLink}` : `404`]);

        return false;
      }),
    );
  }
}
