<div class="d-flex align-items-center">
  <ng-container *ngIf="chatMessage.authorType === ChatMessageAuthorType.AGENT; else leadTemplate">
    <div class="d-flex align-items-baseline">
      <p class="text-travalign-main">{{ portalLead.salesAgent.fullName }}</p>
      <p class="caption text-lightblue-600 ml-2">Your travel expert</p>
    </div>
    <p class="caption text-travalign-main ml-auto">{{ chatMessage.createdAt | date: 'hh:mm a' }}</p>
  </ng-container>

  <ng-template #leadTemplate>
    <p class="text-travalign-main">You</p>
    <img src="assets/icons/done_all.svg" alt="done_all" width="20" height="20" class="ml-auto" />
    <p class="caption text-travalign-main ml-1">{{ chatMessage.createdAt | date: 'hh:mm a' }}</p>
  </ng-template>
</div>

<div
  [ngClass]="{
    'from-lead': chatMessage.authorType === ChatMessageAuthorType.LEAD,
    'from-agent': chatMessage.authorType === ChatMessageAuthorType.AGENT
  }"
  id="{{ chatMessage.id }}"
  data-id="message"
  class="mt-1 p-3 message"
>
  <h3
    [innerHtml]="chatMessage.htmlMessage || '&nbsp;'"
    style="line-height: 22px; display: inline"
  ></h3>

  <chat-message-form
    *ngIf="chatMessageFormFields?.length"
    (formSubmit)="send.emit($event)"
    [chatMessageFormFields]="chatMessageFormFields"
    class="mt-5"
  ></chat-message-form>
</div>
