import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, of, take } from 'rxjs';

import { AuthService } from '@app/services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  canActivate() {
    return this.authService.hasSession().pipe(
      take(1),
      map((hasSession) => {
        if (hasSession) {
          return true;
        }

        void this.router.navigate([`404`]);

        return false;
      }),
      catchError((error: Error) => {
        console.log(error.message || error);

        void this.router.navigate([`404`]);

        return of(false);
      }),
    );
  }
}
