import { FormlyFieldConfig } from '@ngx-formly/core';
import { AbstractControl } from '@angular/forms';
import { CreditCardValidators } from 'angular-cc-library';

import { toLabel } from '@app/ui/pipes/label.pipe';

const EMAIL_REGEXP =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])/;

export function RequiredValidatorMessage(_error: string, field: FormlyFieldConfig) {
  return `Please fill in ${toLabel(field.props?.label as string).toLowerCase()}`;
}

export function BackendValidatorMessage(error: string) {
  return error;
}

export const errorsFormTexts = {
  required: 'Please fill in all the required fields',
  birthday:
    'Please check the birthdays of all passengers. Alternatively, If you need to modify the order details, please reach out to your travel expert for assistance',
  card: 'Please check the card details.',
  optionSubmitError:
    'We are sorry, but we can’t book this flight at the moment. Please reach out to your travel expert for assistance.',
  cardExpiryErrors: 'Please fill in expiration date',
  cardNumberErrors: 'Please fill in card number',
  cardCvcErrors: 'Please fill in security code',
};

export const CCNumberValidator = (control: AbstractControl) => {
  return !CreditCardValidators.validateCCNumber(control);
};

export const EmailValidator = (control: AbstractControl) => {
  const value = control.value as string;

  if (!value) return null;

  return EMAIL_REGEXP.test(value?.toString()?.toLowerCase()) ? null : { email: true };
};
