import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'chat-field-wrapper',
  templateUrl: './chat-field-wrapper.component.html',
  styleUrl: './chat-field-wrapper.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatFieldWrapperComponent extends FieldWrapper {}
