<div
  [ngClass]="{ 'active': activeChatTab }"
  class="d-flex align-items-center p-4 agent"
  data-id="agent"
>
  <div class="d-flex mr-3 p-relative agent-picture">
    <img [src]="portalLead.salesAgent.picture" alt="agent" class="picture" />
    <span class="status"></span>
  </div>

  <div class="d-flex flex-column">
    <h3 data-id="sales-agent-fullname" class="semi-bold text-travalign-main">
      {{ portalLead.salesAgent.fullName }}
    </h3>

    <p class="text-travalign-main">Your travel expert</p>
  </div>

  <button
    *ngIf="activeChatTab && portalLead?.flightRequest?.publishedOptions?.edges?.length"
    (click)="setActiveChatTab.emit(false)"
    class="button secondary ml-auto d-flex align-items-center"
    data-id="go-to-flight"
  >
    Go to Flights
    <img
      src="assets/icons/arrow_forward.svg"
      width="20"
      height="20"
      alt="arrow_forward"
      class="ml-2"
    />
  </button>

  <button
    *ngIf="!activeChatTab"
    (click)="setActiveChatTab.emit(true)"
    class="button ml-auto d-flex align-items-center"
    data-id="open-chat"
  >
    Open chat
    <p
      *ngIf="unreadChatMessagesCount"
      class="caption semi-bold d-flex align-items-center justify-content-center unread"
      data-id="unread-count"
    >
      {{ unreadChatMessagesCount }}
    </p>
  </button>
</div>

<div
  (scroll)="scroll($event)"
  [ngClass]="{ 'active': activeChatTab }"
  class="d-flex flex-column p-4 messages"
  data-id="messages"
>
  <ng-container *ngIf="chatMessages?.length; else emptyMessagesTemplate">
    <chat-message
      *ngFor="let chatMessage of chatMessages; let first = first; let last = last"
      (send)="send($event)"
      [chatMessage]="chatMessage"
      [portalLead]="portalLead"
      [ngClass]="{ 'mt-auto': first, 'mb-3': !last }"
      [chatMessageFormFields]="chatMessage.form | getChatMessageFormFields: last"
    ></chat-message>

    <chat-bubble
      *ngIf="chatMessages[chatMessages.length - 1] | isShowTyping | async"
      (scrollToTyping)="scrollToTyping()"
      id="typing"
      class="mt-3"
    ></chat-bubble>
  </ng-container>
</div>

<div
  *ngIf="activeChatTab && chatMessages[chatMessages.length - 1]?.showTextInput"
  class="p-4 action"
>
  <form [formGroup]="form" (ngSubmit)="send(form.get('message')?.value)">
    <input
      [attr.track-focus]="PortalTrackingEvents.chatAnswerSelected"
      formControlName="message"
      type="text"
      inputmode="text"
      class="input"
      placeholder="Write a message..."
      data-id="input"
      autocomplete="off"
      autocorrect="off"
      spellcheck="false"
    />
  </form>

  <img
    (click)="send(form.get('message')?.value)"
    [ngClass]="form.get('message')?.value ? 'cursor-pointer' : 'cursor-not-allowed'"
    src="assets/icons/{{ form.get('message')?.value ? 'arrow_rounded_blue' : 'arrow_rounded' }}.svg"
    alt="arrow_rounded"
    class="arrow"
    width="24"
    height="24"
    data-id="send"
  />
</div>

<ng-template #emptyMessagesTemplate>
  <p class="mx-auto">
    An agent has been assigned to your request and will message you in this chat shortly.
  </p>
</ng-template>
