import { Injectable } from '@angular/core';
import { BehaviorSubject, map, take } from 'rxjs';
import { Router } from '@angular/router';

import { PortalLeadNode } from '@app/services/api/api.types';
import { ApiService } from '@app/services/api/api.service';

@Injectable({
  providedIn: 'root',
})
export class PortalLeadService {
  portalLead$$ = new BehaviorSubject<PortalLeadNode | null>(null);

  get portalLead$() {
    return this.portalLead$$.asObservable();
  }

  constructor(
    private apiService: ApiService,
    private router: Router,
  ) {}

  getPortalLead(variables: { portalLink: string }) {
    return this.apiService
      .portalLead(variables)
      .pipe(
        take(1),
        map((response) => {
          if (!response || response.errors?.length) {
            void this.router.navigate(['/404']);
          }

          return response.portalLead;
        }),
      )
      .subscribe((portalLead) => this.setPortalLead(portalLead));
  }

  setPortalLead(portalLead: PortalLeadNode | null) {
    return this.portalLead$$.next(portalLead);
  }
}
