import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { of, Subscription } from 'rxjs';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { DateTime } from 'luxon';

import { Option } from '@app/forms/formly/formly-utils';

@Component({
  selector: 'date-field',
  templateUrl: './date-field.component.html',
  styleUrls: ['./date-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateFieldComponent extends FieldType<FieldTypeConfig> implements OnInit, OnDestroy {
  valueChanges$: Subscription;

  months = of(getMonthsOptions());
  days = of(getDaysOptions());
  years = of(getYearsOptions());

  month: Option['value'];
  day: Option['value'];
  year: Option['value'];

  touchedMonth: boolean;
  touchedDay: boolean;
  touchedYear: boolean;

  constructor(
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  @HostListener('document:click', ['$event']) click(event: MouseEvent) {
    const isBlured = !event.composedPath().includes(this.elementRef?.nativeElement as HTMLElement);
    const hasChanges = this.touchedMonth || this.touchedDay || this.touchedYear;

    if (isBlured && hasChanges) {
      this.formControl.markAsTouched();
    }
  }

  ngOnInit() {
    this.valueChanges$ = this.formControl.valueChanges.subscribe((value) => {
      if (value) {
        const parsedDate = DateTime.fromFormat(value as string, 'yyyy-MM-dd');

        this.month = parsedDate.get('month').toString().padStart(2, '0');
        this.day = parsedDate.get('day').toString().padStart(2, '0');
        this.year = parsedDate.get('year').toString();
      } else if (value === undefined) {
        this.month = '';
        this.day = '';
        this.year = '';
      }

      this.changeDetectorRef.detectChanges();
    });

    if (this.formControl.value) {
      this.formControl.updateValueAndValidity();
    }
  }

  setMonth(month: Option['value']) {
    this.month = month;
    this.setFullDate();
  }

  setDay(day: Option['value']) {
    this.day = day;
    this.setFullDate();
  }

  setYear(year: Option['value']) {
    this.year = year;
    this.setFullDate();
  }

  setFullDate() {
    const parsedDate = DateTime.fromFormat(`${this.year}-${this.month}-${this.day}`, 'yyyy-MM-dd');

    if (parsedDate) {
      this.formControl.setErrors(null);
      this.formControl.setValue(parsedDate.toISODate());
    } else {
      this.formControl.setErrors({ invalid: true });
    }
  }

  setMarkAsTouched() {
    const markAsTouched = this.touchedMonth && this.touchedDay && this.touchedYear;

    if (markAsTouched) {
      this.formControl.markAsTouched();
    }
  }

  ngOnDestroy() {
    this.valueChanges$?.unsubscribe();
  }
}

export const getMonthsOptions = () => {
  return [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ].map((month, i) => {
    const index = (i + 1).toString().padStart(2, '0');

    return {
      value: index,
      label: `${index} – ${month}`,
    } as Option;
  });
};

export const getDaysOptions = () => {
  const days = [];

  for (let i = 1; i <= 31; i++) {
    const index = i.toString().padStart(2, '0');

    days.push({
      value: index,
      label: index,
    } as Option);
  }

  return days;
};

export const getYearsOptions = () => {
  const startYear = 1900;
  const currentYear = new Date().getFullYear();

  const years = [];

  let i = currentYear;

  while (i >= startYear) {
    const index = i.toString();

    years.push({
      value: index,
      label: index,
    } as Option);

    i--;
  }

  return years;
};
