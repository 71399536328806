import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { take } from 'rxjs';

import { PortalLeadNode } from '@app/services/api/api.types';
import { ChatService } from '@app/services/chat/chat.service';
import { ChatMessageAuthorType, ChatMessageNode } from '@app/services/chat/chat.types';
import { scrollTo } from '@app/utils/utils';
import { PortalTrackingEvents } from '@app/utils/tracking-events';

@Component({
  selector: 'chat-screen',
  templateUrl: './chat-screen.component.html',
  styleUrl: './chat-screen.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatScreenComponent implements OnInit, OnChanges, OnDestroy {
  ChatMessageAuthorType = ChatMessageAuthorType;
  PortalTrackingEvents = PortalTrackingEvents;

  chatMessages: ChatMessageNode[] = [];

  form = new FormGroup({ message: new FormControl('') });

  debounceScrollTimer: NodeJS.Timeout;
  debounceIntersectionTimer: NodeJS.Timeout;

  markedAsRead = new Set<string>();

  intersectionObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && entry.intersectionRatio > 0) {
        const id = entry.target.getAttribute('id');

        if (id) {
          this.markedAsRead.add(id);
        }

        if (this.debounceIntersectionTimer) {
          clearTimeout(this.debounceIntersectionTimer);
        }

        this.debounceIntersectionTimer = setTimeout(() => this.markAsRead(), debounceMs);
      }
    });
  });

  @Input() portalLead: PortalLeadNode;
  @Input() unreadChatMessagesCount: number | null;

  @Output() setActiveChatTab = new EventEmitter();

  @HostBinding('class.active-chat') @Input() activeChatTab: boolean;

  constructor(
    private chatService: ChatService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.getMessages();
  }

  ngOnChanges(changes: SimpleChanges) {
    const previousValue = changes['unreadChatMessagesCount']?.previousValue as number;
    const currentValue = changes['unreadChatMessagesCount']?.currentValue as number;

    const needsUpdate =
      isFinite(previousValue) && isFinite(currentValue) && currentValue > previousValue;

    if (needsUpdate) {
      this.getMessages('', true);
    }
  }

  getMessages(messageId = '', resetChatMessages = false) {
    const input = { portalLink: this.portalLead.portalLink, maxMessages, messageId };

    this.chatService
      .chatMessages({ input })
      .pipe(take(1))
      .subscribe(({ data }) => {
        const chatMessages = data.chatMessages || [];

        if (resetChatMessages) {
          this.chatMessages = [];
        }

        this.chatMessages.unshift(...chatMessages);

        this.changeDetectorRef.detectChanges();

        const behavior = messageId ? 'instant' : 'smooth';
        scrollTo(`[id="${chatMessages.at(-1)?.id}"]`, behavior);

        Array.from(document.querySelectorAll('[data-id="message"]')).forEach((target) =>
          this.intersectionObserver.observe(target),
        );
      });
  }

  scroll(event: Event) {
    if (this.debounceScrollTimer) {
      clearTimeout(this.debounceScrollTimer);
    }

    this.debounceScrollTimer = setTimeout(() => this.scrollMessages(event), debounceMs);
  }

  scrollToTyping() {
    scrollTo(`[id="typing"]`, 'smooth');
  }

  scrollMessages(event: Event) {
    const scrollTop = (event.target as HTMLElement).scrollTop;

    if (scrollTop < 50) {
      this.getMessages(this.chatMessages[0].id);

      window.track({
        event_name: PortalTrackingEvents.chatScrollingBack,
        lead_portal_link: this.portalLead.portalLink,
      });
    }
  }

  markAsRead() {
    const input = {
      portalLink: this.portalLead.portalLink,
      messages: Array.from(this.markedAsRead),
    };

    this.markedAsRead.clear();

    this.chatService.markChatMessagesReadByLead({ input }).pipe(take(1)).subscribe();

    window.track({
      event_name: PortalTrackingEvents.chatMessagesRead,
      lead_portal_link: this.portalLead.portalLink,
    });
  }

  send(message: string | null = '') {
    if (!message) return;

    const input = { portalLink: this.portalLead.portalLink, message, maxMessages };

    this.chatService
      .sendChatMessage({ input })
      .pipe(take(1))
      .subscribe(({ data }) => {
        const chatMessages = data.sendChatMessage.lastMessages || [];

        const messageId = data.sendChatMessage.messageId;

        this.chatMessages = chatMessages;

        this.changeDetectorRef.detectChanges();

        scrollTo(`[id="${this.chatMessages.at(-1)?.id}"]`);

        if (messageId) {
          window.track({
            event_name: PortalTrackingEvents.portalClientMessageSent,
            lead_portal_link: this.portalLead.portalLink,
            tags: JSON.stringify({ message_id: messageId }),
          });
        }
      });

    this.form.setValue({ message: '' });
  }

  ngOnDestroy() {
    this.intersectionObserver.disconnect();
  }
}

export const maxMessages = 20;
export const debounceMs = 500;
export const pollingIntervalMs = 3000;
