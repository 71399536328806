<div class="d-flex align-items-center justify-content-between px-4 pt-4 px-md-8 pt-md-8">
  <h1 class="semi-bold">Continue to your account</h1>

  <img
    (click)="hideModal.emit()"
    src="assets/icons/close.svg"
    alt="close"
    class="cursor-pointer d-none d-md-inline"
    data-id="hide"
  />
</div>

<p class="mt-2 mx-4 mx-md-8">
  Get better deals, save your details for easy booking, and see all your trips in one place.
</p>

<form [formGroup]="form" class="mt-8 mx-4 mx-md-8">
  <p class="text-jazlyn-700 mb-1">Email</p>

  <input
    [ngClass]="{ 'error': form.touched && form.errors }"
    formControlName="email"
    class="input"
    placeholder="Email"
    data-id="email"
  />

  <p *ngIf="form.errors" class="mt-1 caption text-red-600" data-id="error">{{ activationError }}</p>
</form>

<div class="d-flex flex-column mt-4 mx-4 mx-md-8">
  <button
    (click)="submit()"
    [disabled]="loading || form.invalid"
    class="button"
    data-id="send-email"
  >
    Continue
  </button>
</div>

<div class="mt-3 mx-4 mx-md-8 mb-4 mb-md-8">
  <p class="caption text-jazlyn-700">
    By clicking the «Continue» button, you are agreeing to the BCC terms and conditions and to being
    automatically registered in our system.
  </p>
</div>
